/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #fafafb;
}

.ffarial { font-family: "Arial", "Helvetica", sans-serif; }

.semibold, .semibold_a, .semibold_a * { font-weight: 600; }

span.required { color: #e04949; font-weight: 700; }

.bgcgreen, .bgcgreen_h:hover { background-color: #27a511; }
.bgcgreen_i, .bgcgreen_h_i:hover { background-color: #27a511 !important; }
.bgcdgreen, .bgcdgreen_h:hover { background-color: #1a580d; }
.bgcdgreen_i, .bgcdgreen_h_i:hover { background-color: #1a580d !important; }

.cgreen, .cgreen_h:hover { color: #27a511; }
.cgreen_i, .cgreen_h_i:hover { color: #27a511 !important; }
.cdgreen, .cdgreen_h:hover { color: #1a580d; }
.cdgreen_i, .cdgreen_h_i:hover { color: #1a580d !important; }

.brcgreen, .brcgreen_h:hover { border-color: #27a511; }
.brcgreen_i, .brcgreen_h_i:hover { border-color: #27a511 !important; }
.brcdgreen, .brcdgreen_h:hover { border-color: #1a580d; }
.brcdgreen_i, .brcdgreen_h_i:hover { border-color: #1a580d !important; }

p { margin-bottom: 16px; }
p + p, .subheader + p, .subsubheader + p, .subsubsubheader + p { margin-top: -4px; }

.link {
  color: #27a511;
  text-decoration: underline;
  cursor: pointer;
}
  .link:hover {
    text-decoration: none;
  }



.form-block-container {
  background: #fff;
  padding: 35px 30px 30px;
}
  .form-popup-container .form-block-container {
    border: 4px solid #27a511;
    min-width: 420px;
    max-width: 700px;
  }

  .form-block-container > .subsubheader {
    margin-top: -12px;
  }

  .form {
    position: relative;
  }
    .form-row + .form-row {
      margin-top: 22px;
    }
      .form-label {
        font-weight: 300;
        margin-bottom: 7px;
      }

      .input-container {
        background-color: #fff;
        border: 1px solid #979797;
        padding: 7px 10px 6px;
        position: relative;
        z-index: 1;
      }
        .input-container.error {
          border-color: #e04949;
        }

        .input-container input,
        .input-container textarea,
        .input-container select {
          background: transparent;
          font-size: 1em;
          font-weight: 400;
          line-height: 1.3;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
        .input-container input:not([type="radio"]):not([type="checkbox"]),
        .input-container textarea,
        .input-container select {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }

      .form-error {
        position: relative;
      }
        .errorMessage {
          color: #fff;
          background: #e04949;
          font-size: 12px;
          line-height: 1.3;
          padding: 3px 6px;
          margin: 0px;
        }

    .form-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0px 0px 0px -15px;
    }
      .form-button {
        padding: 22px 0px 0px 15px;
      }


.button-style {
  display: inline-block;
  color: #fff;
  background: #000;
  border: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  min-width: 11em;
  padding: 1em 1.5em;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  -webkit-transition: color .2s ease-in-out 0s;
  -moz-transition: color .2s ease-in-out 0s;
  -o-transition: color .2s ease-in-out 0s;
  transition: color .2s ease-in-out 0s;
}
  .button-style:hover,
  .button-style.button-green {
    color: #fff;
  }

  .button-style.button-green {
    background: #27a511;
  }

  .button-style:before {
    content: "";
    background: #fff;
    position: absolute;
    top: 0px;
    left: 50%;
    right: 50%;
    bottom: 0px;
    margin: 0px auto;
    opacity: 0.25;
    z-index: 1;

    -webkit-transform: skew(-45deg);
    -moz-transform: skew(-45deg);
    -ms-transform: skew(-45deg);
    -o-transform: skew(-45deg);
    transform: skew(-45deg);

    -webkit-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
    -moz-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
    -o-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
    transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
  }
    .button-style:hover:before {
      left: -15%;
      right: -15%;
    }

    .button-style.button-green:before {
      opacity: 0.15;
    }

  .button-style * {
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
  }


ul.ul, ol.ol {
  margin-top: -6px;
  margin-bottom: 20px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 18px;
    margin-top: 0px;
    margin-bottom: 6px;
  }
  ul.ul li, ol.ol li {
    padding-top: 6px;
    padding-bottom: 0px;
    position: relative;
  }
    ul.ul li:before {
      content: "●";
      color: #27a511;
      display: inline-block;
      background-size: contain;
      font-weight: 500;
      margin-right: 0.7em;
      width: 0.45em;
    }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      font-weight: 500;
      color: #27a511;
    }


a.zoom-image {
  display: inline-block;
  position: relative;
}
  a.zoom-image:before {
    content: "";
    background: url('/i/gallery-image-zoom.svg') 50% 50% no-repeat transparent;
    background-size: contain;
    position: absolute;
    top: 45%;
    left: 45%;
    width: 10%;
    height: 10%;
    opacity: 0;

    -webkit-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    -moz-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    -o-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  }
    a.zoom-image:hover:before {
      top: 37%;
      left: 37%;
      width: 26%;
      height: 26%;
      opacity: 0.9 !important;
    }



.unwrapper {
  margin-left: -9999px;
  margin-right: -9999px;
  position: static;
}
.wrapper {
  width: 1210px;
  margin: 0px auto;
  padding: 0px;
  position: relative;
}


/* Structure */
.structure {
  overflow: hidden;
}
  /* Header */
  .header {
    position: relative;
    z-index: 5;
  }
    .header-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
      padding: 15px 0px;
    }
      .header-logo-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex: 1 5 auto;
      }
        .header-slogan {
          font-size: 16px;
          padding-left: 15px;
        }


      .header-contacts {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex: 3 3 auto;
      }
        .header-contacts > div {
          padding-left: 15px;
        }
          .header-phones > div {
            padding-bottom: 4px;
          }
            .header-phones a {
              font-size: 20px;
              font-weight: 600;
              line-height: 1.2;
              text-decoration: none;
              white-space: nowrap;
              cursor: default;
            }
          .header-worktime {
            font-size: 15px;
          }

          .header-buttons {
            text-align: center;
          }
            .header-buttons > div {
              display: inline-flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: stretch;
            }
              .header-buttons .button-style {
                width: 100%;
              }
              .header-buttons .button-style + .button-style {
                margin-top: 4px;
              }


    .header-menu-container {
      background: #27a511;
    }
      .header-menu-button-container,
      .header-menu-checkbox {
        display: none;
      }

      .header-menu-container > ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
      }
        .header-menu-container > ul > li {
          flex: 1 1 auto;
          position: relative;
        }
          .header-menu-container > ul > li.icn-menu-custom1 {
            display: none;
          }

          .header-menu-container > ul > li > div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            position: relative;
          }
            .header-menu-container > ul > li > div:before {
              content: "";
              background: #000;
              display: block;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 0;

              opacity: 0;

              -webkit-transition: opacity .2s ease-in-out 0s;
              -moz-transition: opacity .2s ease-in-out 0s;
              -o-transition: opacity .2s ease-in-out 0s;
              transition: opacity .2s ease-in-out 0s;
            }
              .header-menu-container > ul > li:hover > div:before {
                opacity: .4;
              }
              .header-menu-container > ul > li > div:hover:before {
                opacity: 1;
              }
              .header-menu-container > ul > li.active > div:before {
                opacity: 1;
              }


            .header-menu-container > ul > li > div > div {
              position: relative;
              z-index: 1;
            }
              .header-menu-container > ul > li:not(.with-dropdown) > div > div {
                width: 100%;
              }

              .header-menu-container > ul > li > div a,
              .header-menu-container > ul > li > div > div > span {
                display: block;
                color: #fff;
                font-size: 15px;
                font-weight: 600;
                white-space: nowrap;
                text-align: center;
                padding: 16px 6px;
              }
              .header-menu-container > ul li > div > div > span {
                cursor: default;
              }
              .header-menu-container > ul li > div a {
                cursor: pointer;
              }
                .header-menu-container > ul li.active > div a {
                  font-weight: 700;
                  cursor: default;
                }

                .header-menu-container > ul > li > div a > span,
                .header-menu-container > ul > li > div > div > span > span {
                  text-transform: uppercase;
                }

            .header-menu-container > ul > li > div > .dropmarker {
              color: transparent;
              font-size: 0px;
              width: 10px;
              min-width: 10px;
              cursor: default;
              position: relative;
              margin-right: 6px;
              box-sizing: content-box;
            }
              .header-menu-container > ul > li > div > .dropmarker:before {
                content: "";
                display: block;
                position: absolute;
                background: url('/i/header-menu-dropdown--white.svg') no-repeat 0px 50%/contain transparent;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                margin: auto;
              }

          .header-menu-container > ul > li > ul {
            display: none;
            background: #fff;
            border: 2px solid #000;
            line-height: 1;
            position: absolute;
            top: 100%;
            left: 0px;
            min-width: 100%;
            padding: 3px 15px;
            box-shadow: 0px 3px 2px -1px rgba(0,0,0,.3);

            visibility: hidden;
            opacity: 0;

            -webkit-transition: opacity .2s ease 0s, visibility 0s ease .2s;
            -moz-transition: opacity .2s ease 0s, visibility 0s ease .2s;
            -o-transition: opacity .2s ease 0s, visibility 0s ease .2s;
            transition: opacity .2s ease 0s, visibility 0s ease .2s;
          }
            .header-menu-container > ul.is-desktop-events > li > ul {
              display: block;
            }
            .header-menu-container > ul > li:hover > ul {
              visibility: visible;
              opacity: 1;

              -webkit-transition: opacity .2s ease 0s, visibility 0s ease 0s;
              -moz-transition: opacity .2s ease 0s, visibility 0s ease 0s;
              -o-transition: opacity .2s ease 0s, visibility 0s ease 0s;
              transition: opacity .2s ease 0s, visibility 0s ease 0s;
            }

            .header-menu-container > ul > li > ul > li {
              padding: 15px 15px 13px 0px;
            }
            .header-menu-container > ul > li > ul > li.icn-menu-service-group-null {
              padding-top: 12px;
            }
            .header-menu-container > ul > li > ul > li + li {
              border-top: 1px solid #eaeaea;
            }
              .header-menu-container > ul > li > ul > li > ul {
                padding: 8px 0px 0px 8px;
              }
                .header-menu-container > ul > li > ul > li.icn-menu-service-group-null > div,
                .header-menu-container > ul > li ul > li > div > .dropmarker {
                  display: none;
                }

                .header-menu-container > ul > li ul > li > div a,
                .header-menu-container > ul > li ul > li > div > div > span {
                  display: inline-block;
                  padding: 4px 10px;
                  white-space: nowrap;
                  position: relative;
                }
                  .header-menu-container > ul > li ul > li > div a {
                    cursor: pointer;

                    -webkit-transition: color .2s ease-in-out 0s;
                    -moz-transition: color .2s ease-in-out 0s;
                    -o-transition: color .2s ease-in-out 0s;
                    transition: color .2s ease-in-out 0s;
                  }
                    .header-menu-container > ul > li ul > li > div a:hover,
                    .header-menu-container > ul > li ul > li.active > div a {
                      color: #27a511;
                    }
                    .header-menu-container > ul > li ul > li > div a > span {
                      display: inline-block;
                      border-bottom: 1px solid transparent;
                      position: relative;
                      z-index: 1;

                      -webkit-transition: border-color .2s ease 0s;
                      -moz-transition: border-color .2s ease 0s;
                      -o-transition: border-color .2s ease 0s;
                      transition: border-color .2s ease 0s;
                    }
                      .header-menu-container > ul > li ul > li > div a:hover > span,
                      .header-menu-container > ul > li ul > li.active > div a > span {
                        border-bottom-color: #27a511;
                      }

                .header-menu-container > ul > li > ul > li > div a,
                .header-menu-container > ul > li > ul > li > div > div > span {
                  font-size: 16px;
                  font-weight: 600;
                }
                  .header-menu-container > ul > li > ul > li > div a span,
                  .header-menu-container > ul > li > ul > li > div > div > span span {
                    display: inline-block;
                    text-transform: uppercase;
                    position: relative;
                    z-index: 0;
                  }
                    .header-menu-container > ul > li > ul > li > div a span:after,
                    .header-menu-container > ul > li > ul > li > div > div > span span:after {
                      content: "";
                      background: #abd7a3;
                      height: 5px;
                      position: absolute;
                      left: 0px;
                      right: -12px;
                      bottom: -2px;
                      z-index: -1;
                    }


  /* Content block */
  .content {
    position: relative;
    overflow: hidden;
    z-index: 3;

    /*min-height: 3000px;*/
  }
    .main-rotator-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
      position: relative;
      margin: 16px 0px 10px;
    }
      #main-rotator {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex: 1 420 100%;
        order: 1;
        position: relative;
        overflow: hidden;
      }
        #main-rotator .slick-list {
          width: 100%;
        }
        #main-rotator .slick-list,
        #main-rotator .slick-track {
          height: 100%;
        }

        .main-slide-container {
          display: flex !important;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          height: 100%;
          position: relative;
        }
          #main-rotator > .main-slide-container {
            width: 100%;
            min-width: 100%;
          }

          .main-slide-image {
            width: 50%;
            position: relative;
            z-index: 1;
          }
            .main-slide-image > div {
              background: no-repeat 50% 50%/cover #eee;
              width: 200%;
              height: 100%;
              position: relative;
            }
              .main-slide-image > div:before {
                content: "";
                background: #000;
                display: block;
                height: 0px;
                padding: 38.01652893% 0% 0%;
                opacity: .35;
              }
                .with-mini .main-slide-image > div:before {
                  padding: 45.56962025% 0% 0%;
                }
                .dark-color .main-slide-image > div:before {
                  background: none;
                }

              .main-slide-image a {
                content: "";
                color: transparent;
                font-size: 0px;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 1;
              }

          .main-slide-code-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            width: 50%;
          }
            .main-slide-code-container > div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              width: 200%;
              height: 100%;
              padding: 20px 72px 25px;
            }
              .main-slide-title {
                color: #fff;
                font-size: 34px;
                font-weight: 600;
                line-height: 1.3;
                text-shadow: 1px 2px 4px rgba(0,0,0,.5);
                position: relative;
                z-index: 2;
              }
                .dark-color .main-slide-title {
                  color: #000;
                  text-shadow: 1px 2px 4px rgba(255,255,255,.5);
                }

                .main-slide-title a {
                  color: #fff;
                  text-decoration: none;
                  cursor: pointer;
                }
                  .dark-color .main-slide-title a {
                    color: #000;
                  }

              .main-slide-code {
                color: #fff;
                font-size: 22px;
                line-height: 1.4;
                text-shadow: 1px 2px 3px rgba(0,0,0,.5);
                margin: 15px 0px 0px;
                position: relative;
                z-index: 2;
              }
                .dark-color .main-slide-code {
                  color: #000;
                  text-shadow: 1px 2px 3px rgba(255,255,255,.5);
                }

                .main-slide-code a {
                  color: #fff;
                }
                  .dark-color .main-slide-code a {
                    color: #000;
                  }

              .main-slide-button {
                margin: 25px 0px 0px;
                position: relative;
                z-index: 2;
              }


        #main-rotator-controls {
          height: 0px;
          z-index: 2;
        }
          #main-rotator-controls .slick-prev,
          #main-rotator-controls .slick-next {
            display: inline-block;
            font-size: 0px;
            background: url('/i/arrow-left--white.svg') no-repeat 50% 50%/auto 34px transparent;
            width: 62px;
            cursor: pointer;
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
          }
          #main-rotator-controls .slick-next {
            background-image: url('/i/arrow-right--white.svg');
            left: auto;
            right: 0px;
          }


      .main-mini-slides {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: stretch;
        flex: 1 790 100%;
        order: 2;
        margin: -16px 0px 0px;
        position: relative;
        overflow: hidden;
      }
        .main-mini-slides > div {
          flex: 1 1 auto;
          width: 100%;
          /*height: 50%;*/
          padding: 16px 0px 0px 16px;
        }
          .main-mini-slides[data-count="3"] > div:nth-child(n+2) {
            width: 50%;
          }

          .main-mini-slides > div > div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            height: 100%;
            position: relative;
          }
            .main-slide-mini-image {
              width: 50%;
              position: relative;
              z-index: 1;
            }
              .main-slide-mini-image > div {
                background: no-repeat 50% 50%/cover #eee;
                width: 200%;
                height: 100%;
                position: relative;
              }
                .main-slide-mini-image > div:before {
                  content: "";
                  background: #000;
                  display: block;
                  height: 0px;
                  padding: 42.57425743% 0% 0%;
                  opacity: .35;
                }
                  .dark-color .main-slide-mini-image > div:before {
                    background: none;
                  }

                  .main-mini-slides[data-count="3"] > div:nth-child(n+2) .main-slide-mini-image > div:before {
                    padding: 88.65979381% 0% 0%;
                  }

                .main-slide-mini-image a {
                  color: transparent;
                  font-size: 0px;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                }

            .main-slide-mini-code-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              width: 50%;
              position: relative;
            }
              .main-slide-mini-code-container > div {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                width: 200%;
                height: 100%;
                padding: 15px 25px;
              }
                .main-mini-slides[data-count="3"] > div:nth-child(n+2) .main-slide-mini-code-container > div {
                  padding: 12px 18px;
                }

                .main-slide-mini-title {
                  color: #fff;
                  font-size: 26px;
                  font-weight: 600;
                  text-shadow: 1px 2px 3px rgba(0,0,0,.4);
                  position: relative;
                  z-index: 2;
                }
                  .main-mini-slides:not([data-count="1"]) .main-slide-mini-title {
                    font-size: 22px;
                  }
                  .main-mini-slides[data-count="3"] > div:nth-child(n+2) .main-slide-mini-title {
                    font-size: 18px;
                  }

                  .dark-color .main-slide-mini-title {
                    color: #000;
                    text-shadow: 1px 2px 3px rgba(255,255,255,.4);
                  }

                  .main-slide-mini-title a {
                    color: #fff;
                    text-decoration: none;
                    cursor: pointer;
                  }
                    .dark-color .main-slide-mini-title a {
                      color: #000;
                    }

                .main-slide-mini-code {
                  color: #fff;
                  font-size: 20px;
                  text-shadow: 1px 2px 3px rgba(0,0,0,.4);
                  margin: 10px 0px 0px;
                  position: relative;
                  z-index: 2;
                }
                  .main-mini-slides:not([data-count="1"]) .main-slide-mini-code {
                    font-size: 18px;
                  }
                  .main-mini-slides[data-count="3"] > div:nth-child(n+2) .main-slide-mini-code {
                    font-size: 16px;
                  }

                  .dark-color .main-slide-mini-code {
                    color: #000;
                    text-shadow: 1px 2px 3px rgba(255,255,255,.4);
                  }

                  .main-slide-mini-code a {
                    color: #fff;
                  }
                    .dark-color .main-slide-mini-code a {
                      color: #000;
                    }

              .main-slide-mini-button {
                margin: 20px 0px 0px;
                position: relative;
                z-index: 2;
              }
                .main-mini-slides:not([data-count="1"]) .main-slide-mini-button {
                  display: none;
                }


    .breadcrumbs {
      font-size: 0px;
      background: #f0f0f0;
      padding: 7px 10px;
      margin: 15px 0px 0px;
    }
      .breadcrumbs li {
        display: inline;
        font-size: 0px;
      }
        .breadcrumbs li:before,
        .breadcrumbs a,
        .breadcrumbs span {
          font-size: 13px;
          vertical-align: baseline;
          line-height: 1.2;
        }
        .breadcrumbs li + li:before {
          content: "\232A";
          display: inline-block;
          background: url('/i/arrow-right--black.svg') no-repeat 50% 50%/auto 10px transparent;
          width: 20px;
          color: transparent;
          font-weight: 600;
        }
        .breadcrumbs span {
          color: #333;
          font-weight: 600;
        }
        .breadcrumbs a {
          cursor: pointer;
          text-decoration: underline;
        }
          .breadcrumbs a:hover {
            color: #000;
            text-decoration: none;
          }


    .subheader,
    .subsubheader,
    .subsubsubheader {
      color: #333;
      position: relative;
      font-size: 24px;
      font-weight: 500;
      padding: 22px 0px 0px;
      margin: 0px 0px 20px;
      text-align: left;
      z-index: 2;
    }
      .subheader > span,
      .subheader > div {
        display: inline-block;
        position: relative;
      }
        .subheader > span:after,
        .subheader > div:after {
          content: "";
          background: #abd7a3;
          position: absolute;
          left: 0px;
          right: -20px;
          bottom: 0px;
          height: 12px;
          margin: auto;
          z-index: -1;
        }

      h1.subheader {
        font-weight: 600;
      }

      .quick-link-list + .subheader {
        padding-top: 0px;
      }

    .subsubheader {
      font-size: 21px;
      font-weight: 600;
      padding: 5px 0px 12px;
      margin: 0px;
    }

    .subsubsubheader {
      font-size: 17px;
      padding: 0px 0px 8px;
      margin: 0px;
    }

      .subheader,
      .subsubheader,
      .subsubsubheader,
      .subheader > *,
      .subsubheader > *,
      .subsubsubheader > * {
        text-transform: uppercase;
      }


    .text-container {
      padding: 0px 0px 20px;
      position: relative;
      z-index: 1;
    }


    .quick-link-list {
      font-size: 18px;
      line-height: 1.2;
      padding: 0px;
      margin: 0px 0px 20px;
      position: relative;
      z-index: 1;
    }
      .quick-link-list > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: center;
      }
        .quick-link-list > div {
          justify-content: center;
          margin: 0px -10px;
        }
          .quick-link-list > div > div {
            border-right: 1px solid #111;
            padding: 4px 10px;
          }
            .quick-link-list > div > div:last-child {
              border-right-color: transparent;
            }

            .quick-link-list a {
              font-weight: 400;
              display: inline-block;
              color: #222;
              background: none !important;
              border: 0px dashed #222;
              border-bottom-width: 1px;
              padding: 0px;

              -webkit-transition: color .2s ease-in-out 0s, border-color .1s ease-in-out 0s;
              -moz-transition: color .2s ease-in-out 0s, border-color .1s ease-in-out 0s;
              -o-transition: color .2s ease-in-out 0s, border-color .1s ease-in-out 0s;
              transition: color .2s ease-in-out 0s, border-color .1s ease-in-out 0s;
            }
              .quick-link-list a:hover {
                color: #27a511;
                border-bottom-color: transparent;
              }
              .price-grouped-table-container .quick-link-list:not(.selected) > div > div:first-child a,
              .quick-link-list > div > div.active a,
              .quick-link-list a.active {
                color: #27a511;
                border-bottom-color: transparent;
                font-weight: 700;
                cursor: default;
              }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      color: #27a511;
      font-weight: 500;
      font-style: italic;
      padding: 0px 0px 20px;
      margin: -14px 0px 0px -14px;
      position: relative;
    }
      .gallery-item {
        padding: 14px 0px 0px 14px;
        position: relative;
      }
        .gallery-item.gallery-item-width-2 { width: 50%; }
        .gallery-item.gallery-item-width-3 { width: 33.33333333%; }
        .gallery-item.gallery-item-width-4 { width: 25%; }
        .gallery-item.gallery-item-width-5 { width: 20%; }
        .gallery-item.gallery-item-width-8 { width: 12.5%; }
          .gallery-rotator-image img,
          .gallery-item img {
            max-width: 100%;
          }

      .gallery-notice {
        text-align: center;
        margin: -8px 0px 0px !important;
      }


    .list-view,
    .portfolio-items {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: stretch;
      position: relative;
    }
    .list-view {
      margin: -15px 0px 0px -10px;
      padding: 0px 0px 20px;
    }
      .list-view-item {
        width: 25%;
        padding: 15px 0px 0px 10px;
        position: relative;
      }
        .list-view-item-wrapper {
          background: #f0f0f0;
          border: 2px solid #27a511;
          height: 100%;
          position: relative;
        }
          .list-view-item-image {
            font-size: 0px;
            /*padding: 4px 4px 0px;*/
            position: relative;
            overflow: hidden;
            z-index: 1;
          }
            .list-view-item-image > a,
            .list-view-item-image > span {
              display: block;
              background: no-repeat 50% 50%/cover transparent;
              height: 0px;
              padding-top: 62%;
            }
              .list-view-item-image > a {
                cursor: pointer;
              }

          .list-view-item-content {
            padding: 14px;
            position: relative;
            z-index: 2;
          }
            .list-view-item-name {
              position: relative;
            }
              .list-view-item-name a,
              .list-view-item-name span {
                font-weight: 500;
                text-transform: uppercase;
                text-decoration: none;
              }
              .list-view-item-name a {
                text-decoration: underline;
                cursor: pointer;
              }
                .list-view-item-image:hover + .list-view-item-content .list-view-item-name a,
                .list-view-item-name a:hover {
                  text-decoration: none;
                }

          .list-view-item-notice {
            font-size: 16px;
            padding: 10px 0px 0px;
          }
            .list-view-item-notice *:last-child {
              margin-bottom: 0px;
            }


    .list-block-container + .list-page-group-name {
      padding-top: 10px;
    }
    .list-page-group-name ~ .list-block-container + .list-block-container {
      padding-top: 20px;
    }


    .gallery-rotator-block-container .list-view-item {
      width: 50%;
    }
    .gallery-rotator-block-container.gallery-items-width-3 .list-view-item,
    .gallery-mini-block-container .list-view-item {
      width: 33.333333%;
    }
    .gallery-rotator-block-container.gallery-items-width-4 .list-view-item {
      width: 25%;
    }
      .gallery-rotator {
        position: relative;
      }
        .gallery-rotator:before {
          content: "";
          background: #eee;
          display: block;
          height: 0px;
          padding: 60% 0% 0%;
        }
          .gallery-rotator.slick-initialized:before {
            display: none;
          }

        .gallery-rotator-image {
          position: relative;
          z-index: 0;
        }
          .gallery-rotator > .gallery-rotator-image {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
          }
            .gallery-rotator > .gallery-rotator-image:first-child {
              z-index: 1;
            }

            .gallery-rotator-image a,
            .gallery-rotator-image img {
              width: 100%;
            }

        .gallery-rotator-pager {
          position: relative;
          overflow: hidden;
        }
          .gallery-rotator-pager > div {
            display: none;
            margin: 0px 0px 0px -6px;
          }
            .gallery-rotator-pager > div.slick-initialized {
              display: block;
            }

            .gallery-rotator-pager .slick-track {
              margin: 0px;
            }

            .gallery-rotator-pager-image {
              display: inline-block;
              width: 16.666666%;
              padding: 6px 0px 0px 6px;
              z-index: 0;
            }
              .gallery-rotator-pager-image div {
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                height: 0px;
                padding: 60% 0% 0%;
                cursor: pointer;
                position: relative;
              }
                .gallery-rotator-pager-image div:after {
                  content: "";
                  border: 2px solid transparent;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  z-index: 0;

                  -webkit-transition: border-color .3s ease 0s;
                  -moz-transition: border-color .3s ease 0s;
                  -o-transition: border-color .3s ease 0s;
                  transition: border-color .3s ease 0s;
                }
                  .gallery-rotator-pager-image.slick-current div:after {
                    border-color: #27a511;
                  }

      .gallery-rotator-block-container .list-view-item-name span {
        font-size: 18px;
      }
        .gallery-rotator-block-container.gallery-items-width-3 .list-view-item-name span,
        .gallery-rotator-block-container.gallery-items-width-4 .list-view-item-name span {
          font-size: 16px;
        }


    .gallery-mini-block-container .list-view-item-image {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: flex-start;
      align-items: flex-start;
      padding: 0px 4px 0px 0px;
    }
      .gallery-mini-block-container .list-view-item-image > span {
        background: none;
        height: auto;
        padding: 4px 0px 0px 4px;
      }
        .gallery-mini-block-container .list-view-item-image > span:first-child {
          width: 100%;
        }
        .gallery-mini-block-container .list-view-item-image > span + span {
          width: 33.333333%;
        }
        .gallery-mini-block-container .list-view-item-image > span:nth-child(n + 5) {
          display: none;
        }

        .gallery-mini-block-container .list-view-item-image a {
          display: block;
        }
          .gallery-mini-block-container .list-view-item-image > span + span a.zoom-image:hover:before {
            top: 20%;
            left: 20%;
            width: 60%;
            height: 60%;
          }

        .gallery-mini-block-container .list-view-item-image img {
          width: 100%;
        }


    .service-block-container .list-view {
      justify-content: flex-start;
    }
      .service-block-container .list-view-item {
        width: 33.333333%;
      }
        .service-block-container .list-view-item-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
        }
          .service-block-container .list-view-item-image {
            width: 50%;
            position: unset;
            overflow: unset;
          }
            .service-block-container .list-view-item-image a,
            .service-block-container .list-view-item-image span {
              width: 200%;
              height: unset;
              padding: 0px;
              margin: 0px;
              position: relative;
              z-index: 1;
            }
              .service-block-container .list-view-item-image a:after,
              .service-block-container .list-view-item-image span:after {
                content: "";
                display: block;
                height: 0px;
                padding-top: 58%;
              }


          .service-block-container .list-view-item-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            width: 50%;
            padding: 0px;
            position: unset;
          }
            .service-block-container .list-view-item-content > div {
              width: 200%;
              padding: 25px 20px 18px;
              position: relative;
            }
              .service-block-container .list-view-item-content > div:before {
                content: "";
                background: url('/i/list-view-bg-gradient.svg') repeat-x 0 0/auto 100% transparent;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                opacity: .6;
              }

            .service-block-container .list-view-item-name {
              text-shadow: 1px 2px 3px rgba(0,0,0,.3);
            }
              .service-block-container .list-view-item-name a,
              .service-block-container .list-view-item-name span {
                color: #fff;
                font-size: 20px;
                font-weight: 600;
              }


            .service-block-container .list-view-item-notice {
              color: #fff;
              font-size: 17px;
              font-weight: 400;
              text-shadow: 1px 2px 2px rgba(0,0,0,.2);
              padding-top: 10px;
              position: relative;
            }


    .price-vitrine-item-price {
      color: #ec5b47;
      line-height: 1.2;
      font-weight: 700;
      padding: 10px 0px 0px;
    }
      .price-vitrine-item-price > span {
        font-size: inherit;
        width: 0px;
        height: 0px;
        padding: 0px 3px 0px;
      }


    .portfolio-block-container .list-view {
      margin-left: -11px;
    }
      .portfolio-block-container .list-view-item {
        width: 33.33333333%;
        padding-left: 11px;
      }


    .portfolio-page-block-container .list-view {
      overflow: hidden;
    }
      .portfolio-items {
        flex-grow: 1;
      }
        .portfolio-page-block-container .portfolio-items {
          justify-content: flex-start;
          opacity: 0;
        }
          .portfolio-page-block-container .portfolio-items.initialized {
            opacity: 1;

            -webkit-transition: opacity .3s ease 0s;
            -moz-transition: opacity .3s ease 0s;
            -o-transition: opacity .3s ease 0s;
            transition: opacity .3s ease 0s;
          }


  .advantages-block-container .list-view-item {
    width: 25%;
  }
    .advantages-block-container .list-view-item-wrapper {
      background: none;
      border-width: 3px;
      min-height: 144px;
    }
      .advantages-block-container .list-view-item-content {
        line-height: 1.3;
        padding: 18px 20px 20px;
        z-index: 3;
      }
        .advantages-block-container .list-view-item-name {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
          .advantages-block-container .list-view-item-name span:first-child {
            align-self: flex-start;
            background: no-repeat 50% 50%/contain transparent;
            width: 30px;
            min-width: 30px;
            min-height: 30px;
          }

          .advantages-block-container .list-view-item-name span + span {
            font-weight: 500;
            padding-left: 12px;
          }


    .price-table {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: 0px 0px 20px;
      position: relative;
    }
      .price-table-counter {
        counter-reset: price-table-group-counter;
      }
      .price-table > div {
        border-bottom: 1px solid #e8e8e8;
      }
        .price-grouped-table-container .price-table:not(.selected) > div:not(:first-child),
        .price-grouped-table-container .price-table.selected > div:not(.active),
        .price-grouped-table-container .price-table-group {
          display: none;
        }

      .price-table > div + div {
        margin-top: 35px;
      }
        .price-grouped-table-container .price-table > div + div {
          margin-top: 0px;
        }

        .price-table > div > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
        }
        .price-table-group {
          justify-content: flex-start !important;
          padding: 0px 0px 14px 30px;
        }
          .price-table-counter .price-table-group {
            counter-reset: price-table-subheader-counter;
          }
          .price-table-group span {
            color: #27a511;
            font-size: 22px;
            font-weight: 700;
            text-transform: uppercase;
          }
            .price-table-counter .price-table-group span:before {
              content: counter(price-table-group-counter) ". ";
              counter-increment: price-table-group-counter;
            }

        .price-table-head {
          color: #fff;
          background: #1a500e;
          align-items: flex-end !important;
        }

        .price-table-subheader {
          color: #27a511;
          background: #fff;
          border: solid #e8e8e8;
          border-width: 1px 0px;
          position: relative;
          z-index: 1;
        }
        .price-table-head + .price-table-subheader {
          border-top-width: 0px;
        }

        .price-table-row {
          background: #fff;
        }
          .price-table-row.odd {
            background: #f0f0f0;
          }
          .price-name,
          .price-price,
          .price-unit {
            font-size: 16px;
            font-weight: 400;
            padding: 7px 2px;
            flex-basis: 100%;
            flex-shrink: 4;
          }
          .price-name {
            padding-left: 30px;
          }
          .price-price,
          .price-unit {
            text-align: center;
            flex-shrink: 8;
          }
            .price-price > span {
              font-size: inherit;
              width: 0px;
              height: 0px;
              padding: 0px 2px 0px;
            }

            .price-table-head .price-name,
            .price-table-head .price-price,
            .price-table-head .price-unit {
              font-size: 15px;
              font-weight: 600;
              line-height: 1.2;
              text-transform: uppercase;
              padding-top: 14px;
              padding-bottom: 13px;
            }
            .price-table-subheader .price-name {
              font-size: 15px;
              font-weight: 600;
              text-transform: uppercase;
              padding-top: 11px;
              padding-bottom: 10px;
            }
              .price-table-counter .price-table-subheader .price-name:before {
                content: counter(price-table-group-counter) "." counter(price-table-subheader-counter) ". ";
                counter-increment: price-table-subheader-counter;
                font-weight: 500;
              }


    .portfolio-info-icons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: center;
      margin: -8px 0px 0px -22px;
    }
      .portfolio-block-container .portfolio-info-icons {
        font-size: 15px;
        padding-top: 2px;
        margin-left: -15px;
      }

      .portfolio-info-icons > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        padding: 8px 0px 0px 22px;
      }
        .portfolio-block-container .portfolio-info-icons > div {
          padding-left: 15px;
        }
        .portfolio-block-container .portfolio-info-icons .address-item {
          width: 100%;
        }

        .portfolio-info-icons img {
          height: 18px;
          margin-right: 8px;
        }

        .portfolio-info-icons .price-item > span,
        .portfolio-info-icons .price-stuff > span + span {
          line-height: 1;
          border-bottom: 1px dashed #222;
          cursor: pointer;
        }
          .portfolio-info-icons .price-item > span:hover,
          .portfolio-info-icons .price-stuff > span + span:hover {
            border-bottom-color: transparent;
          }

          .portfolio-info-icons .price-item > span > span,
          .portfolio-info-icons .price-stuff > span > span {
            font-size: inherit;
            width: 0px;
            height: 0px;
            padding: 0px 2px 0px;
          }

        .portfolio-info-icons .price-stuff > span:first-child {
          color: #1a500e;
          font-size: 14px;
          font-weight: 600;
          margin-right: 5px;
        }

      .portfolio-info-icons + div {
        padding: 12px 0px 0px;
      }


    .hide-after:before,
    .hide-after ~ * {
      display: none !important;
    }


    .js-show-hide {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }
      .js-show-hide > div:first-child {
        line-height: 1;
        position: relative;
        order: 1;
      }
        .js-show-hide.active > div:first-child {
          order: 3;
        }

        .js-show-hide > div:first-child > span {
          color: #27a511;
          border-bottom: 1px dashed #27a511;
          font-weight: 500;
          cursor: pointer;
        }
          .js-show-hide > div:first-child > span:hover {
            border-bottom-color: transparent;
          }

      .js-show-hide > div + div {
        display: none;
        position: relative;
        order: 2;
      }
        .js-show-hide.active > div + div {
          display: block;
        }


    .incut {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      background: #fafafb;
      border: 4px solid #1a580d;
      margin: 0px 0px 25px;
      position: relative;
    }
      .incut:before,
      .incut > div {
        flex: 1 1 auto;
      }
      .incut:before {
        content: "";
        font-size: 0px;
        background: url('/f/upload/incut-icon.svg') no-repeat 50% 50%/40% transparent;
        width: 90px;
        min-width: 90px;
        max-width: 90px;
      }

      .incut > div {
        width: 100%;
        padding: 18px 20px 20px;
      }



    .response-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: stretch;
      padding: 0px 0px 25px;
      margin: -35px 0px 0px -35px;
      z-index: 1;
    }
      .response-item {
        width: 50%;
        padding: 35px 0px 0px 35px;
      }
        .response-item > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: stretch;
          background: #f0f0f0;
          border-radius: 30px 30px 30px 0px;
          height: 100%;
          padding: 22px 28px 18px 22px;
        }
          .response-item > div > div:first-child {
            text-align: justify;
            font-style: italic;
            line-height: 1.35;
            position: relative;
          }

          .response-item > div > div + div {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;
            font-size: 15px;
            padding: 10px 0px 0px;
          }
            .response-item > div > div + div > div:first-child {
              font-weight: 600;
            }

            .response-item > div > div + div > div + div {
              color: #505050;
              font-size: 14px;
              padding-left: 15px;
            }


    .response-form-container .form-block-container {
      max-width: 800px;
      margin: 20px auto 0px;
    }
      .response-form-container .input-container textarea {
        height: 90px;
      }



    .faq-list {
      padding: 0px 0px 20px;
      margin: -10px 0px 0px;
    }
      .faq-list > div {
        padding: 10px 0px 0px;
      }
        .faq-list > div > div {
          border: 2px solid #000;
        }
          .faq-list > div > div > div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            color: #fff;
            background: #27a511;
            font-weight: 400;
            line-height: 1.3;
            padding: 12px 15px;
            margin: -2px;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            z-index: 1;

            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
            .faq-list > div > div > div:first-child:hover,
            .faq-list > div > div.active > div:first-child {
              background: #000;
            }

            .faq-list > div > div > div:first-child > span + span {
              width: 14px;
              min-width: 14px;
              position: relative;
            }
              .faq-list > div > div > div:first-child > span + span:after {
                content: '';
                display: flex;
                background: url('/i/faq-list-plus.svg') no-repeat 50% 50%/contain transparent;
                line-height: 0;
                height: 100%;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                position: absolute;
                z-index: 1;

                -webkit-transition: transform 0.2s ease 0s;
                -moz-transition: transform 0.2s ease 0s;
                -o-transition: transform 0.2s ease 0s;
                transition: transform 0.2s ease 0s;
              }
              .faq-list > div > div.active > div:first-child > span + span:after {
                transform-origin: 50% 50%;
                transform: rotate(-45deg) scale(1.2);
              }


            .faq-list > div > div > div:first-child + div {
              display: none;
              overflow: hidden;
              vertical-align: bottom;
            }
              .faq-list > div > div > div:first-child + div > div {
                padding: 12px 15px 10px;
              }
                .faq-list > div > div > div:first-child + div > div p:last-child {
                  margin-bottom: 0px;
                }



  /* Contact */
  .contact-map-block-container {
    background: #eee;
    position: relative;
  }
    .contact-map-placeholder {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 1;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
      .contact-map-placeholder:before {
        content: "Загрузка карты...";
        margin: 0px 0px 0px 350px;
      }

    #contact-map-container {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 2;
    }
    .contact-info-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 1210px;
      min-height: 520px;
      padding: 30px 0px 30px 10px;
      margin: 0px auto;
    }
      .contact-info-wrapper {
        display: inline-block;
        background: #fff;
        box-shadow: inset 0 0 0 1px rgba(0,0,0,.15), 0 5px 15px -7px rgba(0,0,0,.5);
        width: 350px;
        padding: 25px;
        position: relative;
        z-index: 3;
      }
        .contact-info-wrapper > div + div {
          padding-top: 20px;
        }
          .contact-info-label {
            font-size: 18px;
            font-weight: 600;
            padding: 0px 0px 4px;
          }

          .contact-phones > div > div + div,
          .contact-emails > div > div + div {
            padding-top: 2px;
          }
          .contact-address > div > div + div {
            padding-top: 12px;
          }
            .contact-phones div:not(.contact-worktime) > a {
              font-size: 20px;
              font-weight: 500;
            }

            .contact-worktime {
              font-size: 15px;
              font-weight: 300;
            }

          .contact-address {
            white-space: nowrap;
          }

          .contact-emails a {
            font-weight: 500;
            line-height: 1.1;
            text-decoration: underline;
            cursor: pointer;
          }
            .contact-emails a:hover {
              text-decoration: none;
            }


  .contact-map-block-container + .wrapper {
    margin-top: 30px;
  }



  /* Footer */
  .footer-callback-container {
    background: #1a580d;
    margin: 25px 0px 0px;
  }
    .footer-callback-container .form-block-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: center;
      background: none;
      border: none;
      padding: 20px 0px 22px;
    }
      .footer-callback-container .form-block-container:before {
        content: "";
        background: url('/i/contact-icon-callback.svg') no-repeat 50% 50%/contain transparent;
        width: 52px;
        min-width: 52px;
        min-height: 52px;
        align-self: stretch;
        order: 1;
      }

      .footer-callback-container .subsubheader {
        flex: 35 1 0;
        color: #fff;
        font-size: 18px;
        line-height: 1.2;
        padding: 0px 0px 0px 30px;
        margin: 0px;
        order: 2;
      }
        .footer-callback-container .subsubheader > div {
          text-transform: none;
        }
        .footer-callback-container .subsubheader a {
          color: #fff;
        }


      .footer-callback-container .form-container {
        flex: 50 1 0;
        order: 3;
        padding: 0px 0px 0px 30px;
      }
        .footer-callback-container form {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          margin: 0px;
        }
          .footer-callback-container .form-row {
            flex: 5 1 auto;
          }
          .footer-callback-container .form-row,
          .footer-callback-container .form-button,
          .footer-callback-container #form-success-message {
            padding: 0px;
            margin: 0px;
          }
            .footer-callback-container .form-label {
              display: none;
            }

            .footer-callback-container .input-container {
              border-color: #fff;
              padding: 10px 12px 9px;
            }

          .footer-callback-container .form-buttons {
            flex: 1 1 auto;
            margin: 0px;
          }
            .footer-callback-container .form-button,
            .footer-callback-container .button-style {
              width: 100%;
            }

          .footer-callback-container #form-success-message {
            color: #fff;
            text-align: center;
          }
            .footer-callback-container #form-success-message a {
              color: #fff;
            }


      .footer-callback-container .form-notice {
        color: #fff;
        font-size: 15px;
        flex: 32 1 0;
        order: 4;
        padding: 0px 0px 0px 40px;
      }


  .footer {
    padding: 22px 0px 0px;
    position: relative;
    z-index: 1;
  }
    .footer-content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      padding: 0px 0px 20px;
      margin-left: -25px;
    }
      .footer-menu-container {
        flex: 2 1 auto;
      }
        .footer-menu-container > ul {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }
          .footer-menu-container > ul > li {
            padding: 0px 0px 0px 25px;
          }
            .footer-menu-container .dropmarker {
              display: none;
            }

            .footer-menu-container > ul > li > div {
              border-bottom: 1px solid #222;
              padding: 0px 0px 8px;
            }

              .footer-menu-container > ul li > div a,
              .footer-menu-container > ul li > div span {
                font-size: 16px;
              }
              .footer-menu-container > ul li > div a {
                cursor: pointer;
              }
              .footer-menu-container > ul li > div a:hover,
              .footer-menu-container > ul li.active > div a {
                text-decoration: underline;
              }
              .footer-menu-container > ul li.active > div a {
                color: #27a511;
                font-weight: 600;
              }

              .footer-menu-container > ul > li > div a,
              .footer-menu-container > ul > li > div span {
                font-size: 18px;
                font-weight: 500;
                white-space: nowrap;
              }


            .footer-menu-container > ul > li > ul {
              padding: 8px 0px 0px;
            }
              .footer-menu-container > ul > li > ul > li {
                padding: 2px 0px 0px;
              }


      .footer-contacts {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        flex: 1 1 auto;
        margin-left: 25px;
      }
        .footer-contacts > div {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
          .footer-contact-title {
            font-size: 18px;
            font-weight: 500;
            white-space: nowrap;
            border-bottom: 1px solid #222;
            padding: 0px 0px 8px;
          }

          .footer-address {
            font-size: 16px;
            padding-top: 10px;
          }

          .footer-phones {
            padding-top: 15px;
          }
            .footer-phones > div + div {
              padding-top: 3px;
            }
              .footer-phones a {
                font-size: 18px;
                font-weight: 600;
                white-space: nowrap;
              }

          .footer-buttons {
            padding-top: 18px;
          }
            .footer-buttons .button-style {
              font-size: 12px;
            }


    .footer-copyrights {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      font-size: 15px;
      border-top: 1px solid #222;
      padding: 20px 0px;
    }